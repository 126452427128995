import Link from 'next/link';
import Image from 'next/image';
import useIntlMessage from '@/hooks/use-intl-message';

import { StyledFooter } from '@/styles/index';

const Footer = () => {
  const { intlMessage: m } = useIntlMessage();
  return (
    <StyledFooter>
      <div className="footer-nav">
        <div className="logo-col">
          <Link href="/">
            <a className="footer-logo">
              <Image src="/imgs/pollme_logo.png" alt="pollme nav-logo" layout="fill" />
            </a>
          </Link>
          <div className="footer-lead">{m({ id: 'logoLead', route: 'layout.footer' })}</div>
        </div>
        <div className="footer-menu">
          <div className="menu-header">{m({ id: 'product', route: 'layout.footer' })}</div>
          <div className="menu-list">
            <Link href="/">
              <a>{m({ id: 'home', route: 'layout.footer' })}</a>
            </Link>
            <Link href="/features">
              <a>{m({ id: 'features', route: 'layout.footer' })}</a>
            </Link>
            <Link href="/premium">
              <a>{m({ id: 'premium', route: 'layout.footer' })}</a>
            </Link>
            <Link href="/dashboard">
              <a>{m({ id: 'dashboard', route: 'layout.footer' })}</a>
            </Link>
          </div>
        </div>
        <div className="footer-menu">
          <div className="menu-header">{m({ id: 'resources', route: 'layout.footer' })}</div>
          <div className="menu-list">
            <Link href="/templates">
              <a>{m({ id: 'templates', route: 'layout.footer' })}</a>
            </Link>
            <Link href="/guides">
              <a>{m({ id: 'guides', route: 'layout.footer' })}</a>
            </Link>
          </div>
        </div>
        <div className="footer-menu">
          <div className="menu-header">{m({ id: 'company', route: 'layout.footer' })}</div>
          <div className="menu-list">
            {/* <Link href="/blog">
              <a>Blog</a>
            </Link> */}
            <a href="mailto:sales@pollme.app">{m({ id: 'sales', route: 'layout.footer' })}</a>
            <Link href="/contact">
              <a>{m({ id: 'contact', route: 'layout.footer' })}</a>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        Copyright &copy; {new Date().getFullYear()} -{' '}
        {m({ id: 'copyright', route: 'layout.footer' })}
        <br />
        <div>
          <Link href="/terms">
            <a>{m({ id: 'terms', route: 'layout.footer' })}</a>
          </Link>{' '}
          |{' '}
          <Link href="/privacy">
            <a>{m({ id: 'privacy', route: 'layout.footer' })}</a>
          </Link>{' '}
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
